import { ChainId } from '@deorderbook/contracts'

export function useNetwork() {
  const { chainId, chains } = useChains()

  const explorerLink = computed(() => {
    if (!chainId.value) return ''
    return chains.value.find((chain) => chain.chainId === chainId.value)
      ?.blockExplorerUrls
  })

  /**
   * @description Check if the current network is Testnet (ETH or SCROLL)
   */
  const isTestnet = computed(() => {
    return [ChainId.ETH_SEPOLIA, ChainId.SCROLL_SEPOLIA].includes(chainId.value)
  })

  /**
   * @deprecated Use {@link isEthNetwork} instead.
   */
  const isEthernet = computed(() => {
    return (
      chainId.value !== ChainId.SCROLL_SEPOLIA
      // TODO: when support SCROLL network, revert this code
      // && chainId.value !== ChainId.SCROLL
    )
  })

  /**
   * @description Check if the current network is ETH network (Mainnet or Testnet)
   */
  const isEthNetwork = computed(() => {
    return [ChainId.ETH_MAINNET, ChainId.ETH_SEPOLIA].includes(chainId.value)
  })

  /**
   * @description Check if the current network is SCROLL network (Mainnet or Testnet)
   */
  const isScrollNetwork = computed(() => {
    return [ChainId.SCROLL_SEPOLIA].includes(chainId.value) // TODO: when support SCROLL network, add ChainId.SCROLL
  })
  return {
    chainId,
    explorerLink,
    isTestnet,
    isEthernet,
    isEthNetwork,
    isScrollNetwork,
  }
}
